<template>
  <div>
    <div :class="getClasee()" style="display: flex; align-items: center; justify-content: center" @click="openDetailModal">
      {{ item.location_number }}
    </div>

    <a-modal v-model="visible" :title="$t('货品详情')" width="1200px" @cancel="visible = false" :footer="null">
      <a-table rowKey="id" size="small" :dataSource="item.inventory_items" :columns="columns" :pagination="false" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: ["item", "searchValue"],
  data() {
    return {
      visible: false,
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: this.$t("库位"),
          dataIndex: "material_number",
        },
        {
          title: this.$t("货品名称"),
          dataIndex: "material_name",
        },
        {
          title: this.$t("货品编号"),
          dataIndex: "material_number",
        },
        {
          title: this.$t("货品条码"),
          dataIndex: "material_barcode",
        },
        {
          title: this.$t("货品规格"),
          dataIndex: "material_spec",
        },
        {
          title: this.$t("批次编号"),
          dataIndex: "batch_number",
        },
        {
          title: this.$t("货主"),
          dataIndex: "client_name",
        },
        {
          title: this.$t("库存数量"),
          dataIndex: "remain_quantity",
        },
        {
          title: this.$t("状态"),
          dataIndex: "status_display",
        },
      ],
    };
  },
  methods: {
    getClasee() {
      if (!this.item.location) {
        return "no-location-cell";
      }

      for (const inventory_item of this.item.inventory_items) {
        if (inventory_item.material_number === this.searchValue || inventory_item.material_name === this.searchValue) {
          return "product-query-cell";
        }
      }

      for (const inventory_item of this.item.inventory_items) {
        if (inventory_item.remain_quantity > 0) {
          return "has-stock-cell";
        }
      }

      return "no-stock-cell";
    },
    openDetailModal() {
      if (this.item.location) {
        this.visible = true;
      } else {
        this.$message.warning(this.$t("无库位"));
      }
    },
  },
};
</script>

<style scoped>
.product-query-cell {
  background-color: #52c41a;
  color: white;
}

.has-stock-cell {
  background-color: #108ee9;
  color: white;
}

.no-stock-cell {
  background-color: #faad14;
  color: black;
}

.no-location-cell {
  background-color: #d9d9d9;
  color: black;
}

.product-query-cell,
.has-stock-cell,
.no-stock-cell,
.no-location-cell {
  height: 30px;
  width: 40px;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px;
  font-size: 10px;
}
</style>
